module.exports = {
  scriptSource: 'https://cdn.printos.com/printos-blue-ui/${version}/blue-ui.js',
  defaults: {
    varName: 'location.hostname'
},
  rules: [{
    id: 'dev',
    default: true,
    match: ['daily.gsbprint.net', 'localhost'],
    version: "1.4.1032"
}, {
    id: 'qual',
    match: ['qual.printopt.org'],
    version: '1.4.734'
}, {
    id: 'pressqa',
    match: ['pressqa.printopt.org'],
    version: "1.4.1032"
}, {
    id: 'migration',
    match: 'migration.printopt.org',
    version: "1.4.479"
}, {
    id: 'pressqanext',
    match: 'pressqanext.printopt.org',
    version: '1.4.839'
}, {
    id: 'next-staging',
    match: 'stg.printopt.org',
    version: '1.4.1025'
}, {
    id: 'production',
    match: ['printos.com', 'printos.cn'],
    version: '1.4.1013'
}],
  primaryDomains: ['gsbprint.net', 'printopt.org', 'printos.com', 'printos.cn']
};
